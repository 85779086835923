import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import aboutStyles from "../styles/pages/about.module.css"

import aboutHeaderImg from "../images/about-header-img.png"

const AboutPage = () => (
  <Layout location="about">
    <SEO title="About" />
    <div className={ aboutStyles.description }>
        <div className={ aboutStyles.descriptionImg }>
            <img src={ aboutHeaderImg }/>
        </div>
        <div className={ aboutStyles.descriptionText }>
            <div className={ aboutStyles.descriptionTextWrapper }>
                <h3>Company Profile</h3>
                <p>Creative Caboose is a vibrant hub designed to engage the film industry through post-production, content creation, content branding, talent supply (through sister unit Flair! Talent Bureau), in-film advertising, and movie event marketing.</p>
                <p>Creative Caboose is backed by  rich years of relevant experience in the fields of marketing communications, event management and stage production, graphic arts & designs, and audio-video production.</p>
                <p>Creative Caboose sets its eye on the backend work, but also looks after the other components of the process.</p>
                <p>Creative Caboose encourages and promotes creativity and goes beyond functional contribution to the process, realizing that it is playing an integral part of the whole (motion) picture.</p>
                <p>Creative Caboose believes that a way to go in growing the business is through collaboration, creativity, and technical aptitude. These are kept in mind in providing the different services of the team.</p>
            </div>
        </div>
    </div>
  </Layout>
)

export default AboutPage